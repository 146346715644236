"use client";

import { ReloadIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { Button } from "@/components/ui/button";
import { oktah } from "@/styles/font";
import { useRouter } from "next/router";
import "./globals.css";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const router = useRouter();
  const handleReload = () => {
    router.reload();
  };
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html lang="en" data-theme="prime" className={`${oktah.variable} font-sans`} data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className="flex h-screen flex-col items-center justify-center gap-6">
        <div className="flex flex-col items-center gap-1">
          <h1 className="text-3xl">Oooops...</h1>
          <p className="text-foreground/80">Something went wrong</p>
        </div>

        <Button onClick={handleReload} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
          Reload
          <ReloadIcon data-sentry-element="ReloadIcon" data-sentry-source-file="global-error.tsx" />
        </Button>
      </body>
    </html>;
}